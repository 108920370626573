<template>
  <div class="mainx font-inter d-flex flex-column" style="gap: 24px">
    <div>
      <BaseHeader title="Potencial de receita"></BaseHeader>
      <b-breadcrumb
        :items="[{ text: 'Dashboard', href: '/dashboard' }, { text: $t('views.seller.reports.text_1428'), href: '/relatorios' }, { text: 'Potencial de receita' }]"
        class="bg-white m-0 p-0"></b-breadcrumb>
    </div>
    <div class="divider my-0"></div>

    <!-- Filters -->
    <div class="filters d-flex" style="gap: 8px">
      <div class="multiselect-container col-4 p-0">
        <BaseSearchSelect 
          id="product-filter"
          v-model="selectedProducts"
          name="product"
          label="name"          
          track-by="id"
          deselect-label=""
          selectLabel=""
          selectedLabel=""
          :options="productsOptions" 
          :placeholder="$t('views.seller.reports.text_1430')"
          :allow-empty="true"
          :multiple="false" 
          :searchable="true"
          :options-limit="10"
          :loading="loadingProducts"
          @search-change="searchProducts"
        >
          <span slot="noOptions">
            {{ $t("views.seller.reports.text_1330") }}
          </span>
          <span slot="noResult">
            {{ $t("views.seller.reports.text_1331") }}
          </span>
        </BaseSearchSelect>
      </div>
      <b-col cols="2" class="p-0">
        <BaseSearchSelect
          v-model="filterSearch.monthYear"
          name="monthYear"
          label="name"   
          track-by="id"
          deselect-label=""
          selectLabel=""
          selectedLabel=""
          placeholder="Mês/ano"
          :options="monthYearOptions" 
          :allow-empty="true"
          :searchable="true"
        />
      </b-col>
      <b-col cols="2" class="p-0">
        <BaseNewButton
            variant="secondary"
            :disabled="!hasFilterFilled"
            @click.prevent="handleClearFilters"
          >
            {{ $t("common.text_0003") }}
        </BaseNewButton>
      </b-col>
      <!-- 
      <div class="col-2 p-0">
        <div class="date-range-picker-container">
          <date-range-picker 
            ref="picker" 
            opens="right" 
            :locale-data="localeDate" 
            :showWeekNumbers="false"
            :showDropdowns="true" 
            :autoApply="false" 
            :ranges="dateRanges" 
            @update="dateRanger" 
            v-model="dateRange"
          >
            <template v-if="dateRange.startDate && dateRange.endDate" v-slot:input="picker"
              style="min-width: 350px" class="p-0">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
            <template v-else v-slot:input class="btn-table" :title="$t('views.seller.reports.text_1429')">
              {{ $t("views.seller.reports.text_1429") }}
            </template>
          </date-range-picker>
        </div>
      </div>
      -->
      <!-- 
      <div class="col-2 p-0">
          <BaseNewButton
            variant="secondary" 
            icon="funnel" 
            iconPosition="left" 
            type="button"
            style="padding: 9px 12px !important; height: 38px !important;"
            @click="showFilters = true"
          >
            {{ $t("common.text_0001") }}
          </BaseNewButton>
        </div>
        -->
    </div>
    <!-- Filters -->

    <div class="container-x">
      <Container title="Potencial de receita para os próximos meses"
        subtitle="Receitas esperadas para os próximos meses. Essa projeção não contempla inadimplência, reembolso e cashback.">
        <BarChartData 
          :data="bar_chart_data['data'] || []" 
          :labels="bar_chart_data['labels'] || []"
          :data_line="line_chart_data['data'] || []" 
          :labels_line="line_chart_data['labels'] || []" 
          :title="'Total'"
          :loading="loading" />
      </Container>
      <div class="box-stateless">
        <Table :columns="['Mês', 'Vendas', 'Cartão de crédito', 'Boleto', 'Internacional', 'Valor Total']" :rows="data">
        </Table>
      </div>
    </div>

    <!-- Modal Filters -->
    <b-modal
      v-model="showFilters" 
      content-class="font-inter px-4"
      body-class="p-0"
      size="lg"
      hide-footer 
      hide-header centered
    >
      <div class="d-flex flex-column" style="gap: 48px">
        <div class="d-flex flex-column" style="gap: 24px">
          <h5 class="modal-title">{{ $t("common.text_0001") }}</h5>
          <b-row>
            <b-col cols="6">
              <BaseSearchSelect
                v-model="modalFilters.monthYear"
                inputLabel="Mês/ano"
                name="monthYear"
                label="name"   
                track-by="id"
                deselect-label=""
                selectLabel=""
                selectedLabel=""
                :options="monthYearOptions" 
                :placeholder="$t('common.text_0004')"
                :allow-empty="true"
                :searchable="true"
              />
            </b-col>
            <b-col cols="6">
              <BaseInput 
                :label="$t('views.seller.reports.text_1433')"
                :placeholder="$t('views.seller.reports.text_1439')"
                v-model="modalFilters.salesQtd"
                v-mask="['#########']"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <BaseMoneyInput
                :label="$t('views.seller.reports.text_1434')"
                v-model="modalFilters.creditCard"
                v-bind="moneyConfig"
              />
            </b-col>
            <b-col cols="6">
              <BaseMoneyInput
                :label="$t('views.seller.reports.text_1435')"
                v-model="modalFilters.boleto"
                v-bind="moneyConfig"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <BaseMoneyInput
                :label="$t('views.seller.reports.text_1436')"
                v-model="modalFilters.international"
                v-bind="moneyConfig"
              />
            </b-col>
            <b-col cols="6">
              <BaseMoneyInput
                :label="$t('views.seller.reports.text_1437')"
                v-model="modalFilters.grossValue"
                v-bind="moneyConfig"
              />
            </b-col>
          </b-row>
        </div>

        <div class="d-flex justify-content-end p-0" style="gap: 12px; margin: 0;">
          <BaseNewButton
            variant="secondary"
            type="button"
            @click="showFilters = false"
          >
            {{ $t("common.text_0002") }}
          </BaseNewButton>
          <BaseNewButton
            variant="secondary"
            :disabled="!hasFilterFilled"
            @click.prevent="handleClearFilters"
          >
            {{ $t("common.text_0003") }}
          </BaseNewButton>
          <BaseNewButton
            variant="primary"
            type="button"
            @click="handleApplyFilters"
            :disabled="!hasFilterFilled"
          >
            {{ $t("common.text_0005") }}
          </BaseNewButton>
        </div>
      </div>
    </b-modal>
    <!-- Modal Filters -->
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { Money } from 'v-money';

import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker';

import _ from 'lodash';

import ReportService from '@/services/resources/ReportService';
import ProductService from '@/services/resources/ProductService';

import BaseHeader from '@/components/BaseHeader.vue';
import Container from '@/components/Seller/Reports/Container.vue';
import Table from '@/components/Seller/Reports/Table.vue';
import BarChartData from '@/components/Seller/Reports/BarChartData.vue';
import BaseSearchSelect from '@/components/BaseSearchSelect.vue';
import BaseInput from '../../../components/BaseInput.vue';

const Report = ReportService.build();
const productService = ProductService.build();

moment.locale('pt-br');

export default {
  components: {
    BaseHeader,
    Container,
    Table,
    BarChartData,
    Multiselect,
    DateRangePicker,
    Money
  },
  data() {
    return {
      data: [],
      loading: false,
      bar_chart_data: [],
      line_chart_data: [],
      productsOptions: [],
      selectedProducts: [],
      loadingProducts: false,
      showFilters: false,
      monthYearOptions: [],
      moneyConfig: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      filterSearch: {
        products: [],
        startDate: moment.tz('America/Sao_Paulo').subtract(12, 'months').format('YYYY-MM-DD'),
        endDate: moment.tz('America/Sao_Paulo').add(2, 'months').endOf('month').format('YYYY-MM-DD'),
        monthYear: '',
        creditCard: '',
        boleto: '',
        international: '',
        grossValue: '',
        salesQtd: ''
      },
      modalFilters: {
        monthYear: null,
        creditCard: '',
        boleto: '',
        international: '',
        grossValue: '',
        salesQtd: ''
      },
      initialDateRange: {
        startDate: moment.tz('America/Sao_Paulo').subtract(12, 'months'),
        endDate: moment.tz('America/Sao_Paulo').add(2, 'months').endOf('month'),
      },
      dateRange: {
        startDate: moment.tz('America/Sao_Paulo').subtract(12, 'months'),
        endDate: moment.tz('America/Sao_Paulo').add(2, 'months').endOf('month'),
      },
      localeDate: {
        direction: 'ltr',
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        monthNames: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        firstDay: 0,
      }
    };
  },
  computed: {
    hasFilterFilled() {
      if (this.filterSearch.monthYear || this.filterSearch.products.length) {
        return true;
      }
      return false;
    },
    dateRanges() {
			let today = new Date();
			today.setHours(0, 0, 0, 0);

			let yesterday = new Date();
			yesterday.setDate(today.getDate() - 1);
			yesterday.setHours(0, 0, 0, 0);

			let lastWeek = new Date();
			lastWeek.setDate(today.getDate() - 7);
			lastWeek.setHours(0, 0, 0, 0);

			return {
				[this.$t("locale_date.today")]: [today, today],
				[this.$t("locale_date.yesterday")]: [yesterday, yesterday],
				[this.$t("locale_date.last_week")]: [lastWeek, today],
				[this.$t("locale_date.current_month")]: [
					new Date(today.getFullYear(), today.getMonth(), 1),
					new Date(today.getFullYear(), today.getMonth(), 30),
				],
				[this.$t("locale_date.current_year")]: [
					new Date(today.getFullYear(), 0, 1),
					new Date(today.getFullYear(), 11, 31),
				],
				[this.$t("locale_date.last_month")]: [
					new Date(today.getFullYear(), today.getMonth() - 1, 1),
					new Date(today.getFullYear(), today.getMonth(), 0),
				],
				[this.$t("locale_date.every_period")]: [
					new Date(2020, 0, 1),
					new Date(today.getFullYear(), today.getMonth(), today.getDate()),
				],
			};
		},
  },
  watch: {
		selectedProducts: function (products) {
      this.filterSearch.products = [products.id];
		},
		filterSearch: {
			handler: function () {
				// this.$parent.fetchReport();
        this.fetchData();
			},
			deep: true,
		},
	},
  methods: {
    fetchData() {
      this.loading = true;
      const filters = {
        ...this.filterSearch,
        monthYear: this.filterSearch.monthYear.id || '',
      };

      Report.search({ 
        url: 'sales-availability',
        ...filters,
        type: 'full' 
      })
        .then((response) => {
          this.data = response['sales_grouped_by_month_table'];
          this.bar_chart_data = response['sales_grouped_by_month_bar_chart'];
          this.line_chart_data = response['sales_grouped_by_month_line_chart'];
          this.loading = false;

          if (!this.monthYearOptions.length && this.data.length) {
            this.monthYearOptions = this.data.map(item => ({
              id: moment(item['Mês'], 'MMM/YYYY', 'pt').format('YYYY-MM'),
              name: item['Mês']
            }));
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    async fetchProducts(search = null) {
      try {
        this.loadingProducts = true;

        const data = {
          list: true,
          with_participants: true,
        };

        if (search) {
          data.search = search;
        }

        const products = await productService.search(data);

        if (!products.length) {
          return [];
        }
        this.productsOptions = products.map(({ id, name }) => ({ id, name }));
      } catch (error) {
        this.productsOptions = [];
        console.log('PotentialRevenue::fetchProducts', error);
      } finally {
        this.loadingProducts = false;
      }
    },
    searchProducts: _.debounce(async function (search) {
      await this.fetchProducts(search);
    }, 500),
    dateRanger(dateRange) {
			let end = moment(dateRange.endDate);
			let start = moment(dateRange.startDate);

			this.totalDiffDays = end.diff(start, 'days') + 1;

			let startDate = moment
				.tz(dateRange.startDate, 'America/Sao_Paulo')
				.format('YYYY-MM-DD');
			let endDate = moment
				.tz(dateRange.endDate, 'America/Sao_Paulo')
				.format('YYYY-MM-DD');
			this.filterSearch = { ...this.filterSearch, startDate, endDate };
		},
    handleApplyFilters() {
      this.filterSearch = { ...this.filterSearch, ...this.modalFilters };

      if (this.modalFilters.monthYear && this.modalFilters.monthYear.id) {
        this.filterSearch.monthYear = this.modalFilters.monthYear.id;
      }
      this.showFilters = false;
    },
    handleClearFilters() {
      Object.assign(this.modalFilters, {
        ...Object.fromEntries(Object.keys(this.modalFilters).map(key => [key, ''])),
        monthYear: ''
      });
      Object.assign(this.filterSearch, {
        ...Object.fromEntries(Object.keys(this.filterSearch).map(key => [key, ''])),
        ...{
          startDate: this.initialDateRange.startDate.format('YYYY-MM-DD'),
          endDate: this.initialDateRange.endDate.format('YYYY-MM-DD'),
          products: []
        }
      });

      this.dateRange = {
        startDate: this.initialDateRange.startDate.toDate(),
        endDate: this.initialDateRange.endDate.toDate()
      };
      this.monthYearOptions = [];
      this.selectedProducts = [];
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped>
.box-stateless {
  border: 1px solid #D7DAE2;
  box-sizing: border-box;
  border-radius: 8px;
}

.container-x {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
}
</style>
<style>
.box-stateless {
  table thead tr th:first-child {
    border-top-left-radius: 8px;
  }

  table thead tr th:last-child {
    border-top-right-radius: 8px;
  }
}

.date-range-picker-container .reportrange-text {
  padding: 0 !important;
  height: 38px !important;
  display: flex; 
  align-items: center;
  padding: 0px 12px !important;
}

.date-range-picker-container .vue-daterange-picker {
  width: 100%;
}

.potential-revenue-filters .modal-dialog.modal-md {
  min-width: 635px !important;
  font-family: var(--Default-font-family);
}

.potential-revenue-filters .modal-body {
  padding: 0 !important;
}
</style>